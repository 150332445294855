import React from "react";
import FirstSummaryTool from "./FirstSummaryTool";

function SummaryTool() {
  return (
    <>
      <FirstSummaryTool />
    </>
  );
}

export default SummaryTool;
