import React from "react";
import Layout from "components/layout";
import SummaryTool from "components/SummaryTool";
import SEO from "components/seo";

function index() {
  const title = "Free Online Summarization Tool";
  const description =
    "The Tomedes summarization tool creates summaries of texts. You simply input a URL or paste in copy and the tool summarizes the information you have fed in. Ideal for use by translators, writers, content marketers and others who work on translation or with words on a daily basis.";
  const keywords =
    "summary, summarizer, summarizing, summarizing tool, text summary, article summary, document summary";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/tools/text-summarizer"
      />
      <SummaryTool />
    </Layout>
  );
}

export default index;
