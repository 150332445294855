import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSummaryTool } from "hooks/Forms/useSummaryTool";
import { useLocation } from "@reach/router";
import Loader from "components/loader";
import ToolsBreadcrumb from "components/ToolsBredcrumb";

import formCommonPostData from "utils/form-common-post-data";
import { Link, navigate } from "gatsby";
import Toogle_icon from "assets/toogle_icon.svg";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import DownloadIcon from "assets/downloadtool.jpg";
import CopyIcon from "assets/copytools.jpg";
import { Tooltip } from "react-tooltip";
import UploadIcon from "assets/uploadIcon.svg";
import { fileExtension } from "utils/fileextension";
import ReUploadFile from "components/FileUploading/reUploadingfile";
import UnsupportedFile from "components/FileUploading/unsupportedfile";
import FileLoadingInput from "components/FileUploading/fileloadinginput";

function FirstSummaryTool() {
  const { status, mutateAsync } = useSummaryTool();
  const location = useLocation();
  const [toggleTool, setToggleTool] = useState(false);
  const [enteredText, setEnteredText] = useState("");
  const [summarizedText, setSummarizedText] = useState("");
  const [isEnterText, setIsEnterText] = useState(true);
  const [wordCount, setWordCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [urlText, setUrlText] = useState("");
  const [copySuccess, setCopySuccess] = useState("Copy");
  const refDiv = useRef(null);
  const summarizedTextRef = useRef(null);
  const [hasSummary, setHasSummary] = useState(false);
  const [shareId, setShareId] = useState("");
  const [error, setError] = useState(false);
  const [fileUploadLoading, setFileUploading] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [file, setFile] = useState(false);
  const [sizeLimitError, setSizeLImitError] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (!data.url && !data.message) {
      alert("Please enter a page URL or text in the box");
      return;
    }

    setLoading(true);
    const postData = {
      user_text: data.message || data.url,
    };

    try {
      let response;

      // If URL is provided, call mutateAsync
      if (data.url) {
        response = await mutateAsync({ url: data.url });

        if (response.success === 1) {
          setEnteredText(data.url);
          setSummarizedText(response.data.result);
          setHasSummary(true);
          setToggleTool(true);
          reset();
          window.scrollTo(0, 0);
        } else {
          alert(response.message);
        }
      } else {
        // If message is provided, call POST API
        response = await http().post(endpoints.forms.aiTools, {
          tool_name: "summarizer",
          user_text: data.message,
        });

        const newShareId = response?.meta?.share_id;
        setShareId(newShareId);
        setToggleTool(true);
        window.history.pushState(
          {},
          "",
          `/tools/text-summarizer?share-id=${newShareId}`
        );
        window.location.reload();
      }
    } catch (error) {
      console.error("Error during summarization:", error);
      alert("An error occurred while processing your request.");
    } finally {
      setLoading(false);
    }
  };

  const params = new URLSearchParams(location.search);
  const UpdatreshareId = params.get("share-id");

  const displayShareId = shareId ? shareId : UpdatreshareId;

  useEffect(() => {
    const fetchAnalysis = async () => {
      if (!displayShareId) return;

      setLoading(true);
      try {
        const response = await http().get(
          `${endpoints.forms.aiTools}/${displayShareId}`
        );
        const { user_text: sourceText } = response?.meta;

        if (response.result && response.result.length > 0) {
          setEnteredText(sourceText);

          if (Array.isArray(response.result)) {
            setSummarizedText(
              response.result.map((item) => item.summarized_text).join("\n")
            );
          } else if (
            typeof response.result === "object" &&
            response.result.summarized_text
          ) {
            setSummarizedText(response.result.summarized_text);
          }
          setToggleTool(true);
          reset();
          window.scrollTo(0, 0);
        } else {
          console.log(response.message);
        }

        setWordCount(
          sourceText
            ? sourceText.split(/\s+/).filter((word) => word.length > 0).length
            : 0
        );
        setMessageText(sourceText);
        reset({ message: sourceText });
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };

    fetchAnalysis();
  }, [displayShareId]);

  const handleTextChange = (e) => {
    const text = e.target.value.trim();
    setMessageText(text);
    const words = text.split(/\s+/).filter((word) => word.length > 0);
    setWordCount(words.length);
    setHasSummary(false);
  };

  const clearText = () => {
    setWordCount(0);
    setMessageText("");
    reset({ message: "" });
    setSummarizedText("");
    setHasSummary(false);
    navigate(`/tools/text-summarizer/`);
  };

  const handleLinkChange = (e) => {
    const text = e.target.value;
    setUrlText(text);
    setHasSummary(false);
  };

  const clearLink = () => {
    setUrlText("");
    reset({ url: "" });
    setSummarizedText("");
    setHasSummary(false);
  };

  // file Upload

  const uploadFile = async (e) => {
    setSizeLImitError(false);
    setUploadError(false);
    const selectedFile = e.target.files[0];
    const extension = fileExtension(selectedFile.name);

    if (!extension) {
      setUploadError(true);
      setFile(selectedFile);
      return;
    }
    if (selectedFile.size >= 15000000) {
      alert("File is too large. Please upload file less than 10 MB.");
      setSizeLImitError(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setFileUploading(true);
      setUploadError(null);
      const res = await http().post(endpoints.forms.toolsFileUpload, formData);
      setMessageText(res.data.text);
      setValue("message", res.data.text);
    } catch (err) {
      console.log(err, "error");
      setUploadError("Error uploading file. Please try again.");
    } finally {
      setFileUploading(false);
    }
  };

  const toggleInput = (isTextInput) => {
    setIsEnterText(isTextInput);
    if (isTextInput) {
      setUrlText("");
    } else {
      setMessageText("");
    }
    setHasSummary(false);
  };

  const copyText = () => {
    if (summarizedTextRef.current) {
      const textToCopy = summarizedTextRef.current.value;
      console.log(textToCopy, "textcopy");
      navigator.clipboard.writeText(textToCopy);
      setCopySuccess("Copied");
      setTimeout(() => {
        setCopySuccess("Copy");
      }, 1500);
    }
  };

  const downloadCSV = () => {
    if (!summarizedText) return;

    const csvData = `Summarized Text\n"${summarizedText.replace(/"/g, '""')}"`;
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "summarized_text.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="bg-[#F5F5F5] ">
      <div className=" w-full py-7 ">
        <ToolsBreadcrumb />
      </div>

      <div className="pb-[120px]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 mt-12 lg:mt-16">
          <h1 className="text-[#5B93FF] font-semibold text-base font-primary text-center ">
            SUMMARY GENERATOR
          </h1>
          <h2 className="mt-3 text-[#0A2641] font-primary text-[28px] lg:text-[64px] font-bold leading-[48px] lg:leading-[77px] text-center ">
            Summarize your long text for free
          </h2>
          <p className="mt-6 font-opensans max-w-3xl mx-auto text-[#424242] text-lg font-normal text-center">
            Our text summarizer helps you quickly generate concise summaries
            from articles, PDFs, and websites. Just paste your content or a URL,
            and get a summary instantly.
          </p>
          <div className="grid lg:grid-cols-2 gap-3 mt-16">
            <div className="bg-[#ffffff] rounded-lg shadow-lg p-5">
              <div className="flex gap-1">
                <button
                  onClick={() => toggleInput(true)}
                  className={`py-1 px-3 rounded-md font-semibold ${
                    isEnterText
                      ? "text-white bg-[#7AA7FF]"
                      : "text-[#7AA7FF] bg-[#F0F5FF]"
                  }`}
                >
                  Enter Text
                </button>
                <button
                  onClick={() => toggleInput(false)}
                  className={`py-1 px-3 rounded-md font-semibold ${
                    isEnterText
                      ? "text-[#7AA7FF] bg-[#F0F5FF]"
                      : "text-white bg-[#7AA7FF]"
                  }`}
                >
                  Enter URL
                </button>
              </div>
              <div className="mt-4">
                {isEnterText ? (
                  <div>
                    {fileUploadLoading ? (
                      <FileLoadingInput file={file} />
                    ) : sizeLimitError && !fileUploadLoading ? (
                      <ReUploadFile
                        file={file}
                        setFile={setFile}
                        setSizeLImitError={setSizeLImitError}
                        uploadFile={uploadFile}
                      />
                    ) : uploadError && !fileUploadLoading ? (
                      <UnsupportedFile
                        file={file}
                        setSizeLImitError={setSizeLImitError}
                        setFile={setFile}
                        uploadFile={uploadFile}
                        setFileUploadError={setUploadError}
                      />
                    ) : (
                      <textarea
                        name="message"
                        id="message"
                        {...register("message")}
                        value={messageText}
                        className="min-h-[280px] font-opensans placeholder-[#858585] text-black leading-[26px] py-3 px-3 bg-transparent text-lg overscroll-y-none resize-none border-none outline-none block w-full"
                        placeholder="Type or paste your text here"
                        onChange={handleTextChange}
                      />
                    )}
                    <div className="flex justify-between items-center p-4 mt-8">
                      {messageText ? (
                        <p className="text-right mt-2 text-sm text-[#9C9C9C]">
                          {wordCount} words
                        </p>
                      ) : (
                        <>
                          <label
                            htmlFor="uploadFile"
                            className="flex gap-2 cursor-pointer"
                          >
                            <img src={UploadIcon} alt="upload_icon" />
                            <span>Upload File</span>
                          </label>
                          <input
                            type="file"
                            className="hidden"
                            id="uploadFile"
                            onChange={uploadFile}
                          />
                        </>
                      )}
                      <div className="flex gap-2">
                        <button
                          type="button"
                          className={` ${
                            wordCount > 0
                              ? "text-[#0A2641] border border-[#0A2641] cursor-pointer"
                              : "border border-[#B0B0B0] text-[#B0B0B0]"
                          } flex gap-2 bg-white px-4 py-2 rounded-md`}
                          onClick={clearText}
                          disabled={wordCount === 0}
                        >
                          Clear text
                        </button>
                        <button
                          type="button"
                          onClick={handleSubmit(onSubmit)}
                          disabled={hasSummary}
                          className={`bg-[#0A2641] ${
                            hasSummary ? "opacity-50 cursor-not-allowed" : ""
                          } text-white px-4 py-2 rounded-md`}
                        >
                          {loading ? <Loader /> : "Summarize text"}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <textarea
                      {...register("url")}
                      value={urlText}
                      placeholder="Paste your link here"
                      className={`min-h-[220px] font-opensans placeholder-[#858585] text-[#1463FF] leading-[26px] py-3 px-3 bg-transparent text-lg overscroll-y-none resize-none border-none outline-none block w-full`}
                      onChange={handleLinkChange}
                    />
                    <div className="flex justify-between items-center p-4 mt-8">
                      <span></span>
                      <div className="flex gap-2">
                        <button
                          type="button"
                          className={` ${
                            urlText.length > 0
                              ? "text-[#0A2641] border border-[#0A2641] cursor-pointer"
                              : "border border-[#B0B0B0] text-[#B0B0B0]"
                          } flex gap-2 bg-white px-4 py-2 rounded-md`}
                          onClick={clearLink}
                          disabled={urlText.length === 0}
                        >
                          Clear link
                        </button>
                        <button
                          type="button"
                          onClick={handleSubmit(onSubmit)}
                          disabled={hasSummary}
                          className={`bg-[#0A2641] ${
                            hasSummary ? "opacity-50 cursor-not-allowed" : ""
                          } text-white px-4 py-2 rounded-md`}
                        >
                          {loading ? <Loader /> : "Summarize link"}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
              className="bg-[#ffffff] relative  rounded-lg shadow-lg p-5"
            >
              <div className="flex justify-between gap-1">
                <p className="text-base text-[#B0B0B0] font-bold leading-6 font-opensans">
                  Summarized text
                </p>
              </div>
              <div className="mt-4">
                <textarea
                  value={summarizedText}
                  ref={summarizedTextRef}
                  className="min-h-[280px] border-none outline-none font-opensans placeholder-[#858585] text-black leading-[26px] py-3 px-3 bg-transparent text-lg overscroll-y-none resize-none  block w-full"
                  placeholder="Summarized text will appear here"
                  readOnly
                />
              </div>
              <div>
                {summarizedText && (
                  <div className="bg-white px-4 absolute bottom-0 left-0 py-6 w-full flex flex-wrap items-center justify-end ">
                    <div className="flex gap-4 ">
                      <button
                        data-tooltip-id="copy-tooltip"
                        data-tooltip-content={copySuccess}
                        onClick={copyText}
                      >
                        <img src={CopyIcon} alt="/copyicons" />
                      </button>
                      <button
                        data-tooltip-id="download-tooltip"
                        data-tooltip-content="Download"
                        onClick={downloadCSV}
                      >
                        <img src={DownloadIcon} alt="/downloadicon" />
                      </button>
                    </div>
                    <Tooltip
                      id="copy-tooltip"
                      place="top"
                      multiline={true}
                      className="z-50"
                    />
                    <Tooltip
                      id="download-tooltip"
                      place="top"
                      multiline={true}
                      className="z-50"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <p className="w-full text-center text-base mt-12 pb-4 text-[#9C9C9C]">
          Assisted by AI tools. Inaccuracies may occur.
        </p>
      </div>
    </div>
  );
}

export default FirstSummaryTool;
